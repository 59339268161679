import React from 'react'
import PhoneInput from 'react-phone-input-2'
// interface PhoneNumProps {
//   fullWidth?: string
//   props: any
//   inputProps: any
// }
export const PhoneNumInput = props => {
  const { fullWidth, ...rest } = props
  return (
    <PhoneInput
      {...rest}
      style={{ width: fullWidth ? '100%' : null }}
      //   inputStyle={{
      //     width: '50%',
      //   }}
      //   inputProps={
      //     {
      //       // style: { width: fullWidth ? '100%' : null },
      //       // className: 'phone-no-input',
      //     }
      //   }
    />
  )
}
